
import Vue from "vue";

export default Vue.extend({
  name: "PaymentSuccessfulDialog",
  components: {},
  props: {
    dialog: Boolean,
    order: Object,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    printReceipt() {
      const url =
        window.location.origin + "/invoice/" + this.order.id + "?method=print";
      window.open(url, "_blank");
    },
    dashboard() {
      this.$router.push("/dashboard");
    },
  },
  watch: {},
});
