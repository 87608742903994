import { render, staticRenderFns } from "./UpgradeOptionsDialog.vue?vue&type=template&id=7869486b&scoped=true"
import script from "./UpgradeOptionsDialog.vue?vue&type=script&lang=ts"
export * from "./UpgradeOptionsDialog.vue?vue&type=script&lang=ts"
import style0 from "./UpgradeOptionsDialog.vue?vue&type=style&index=0&id=7869486b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7869486b",
  null
  
)

export default component.exports