
import { LicenseType } from "@/api";
import {
  BASE_FREE_SUMMARY,
  BASE_PRO_SUMMARY,
  getPlanSummary,
  getStorageInNumber,
  IPlanCosts,
  PROTIER_BASE_PRICE,
} from "@/utils";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "AccountPlanPaymentSummary",
  props: {
    isFree: Boolean,
    planCosts: Object as PropType<IPlanCosts>,
    cardOnFile: String,
  },
  data() {
    return {};
  },
  computed: {
    baseSummary: function (): any[] {
      if (this.isFree) {
        return BASE_FREE_SUMMARY;
      } else {
        return BASE_PRO_SUMMARY;
      }
    },
    summary: function (): any[] {
      if (this.isFree) {
        return [];
      }
      return getPlanSummary(this.planCosts);
    },
    total: function (): string {
      if (this.isFree) {
        return "0";
      }
      return this.planCosts.upgrading?.total;
    },
    disableCheckout: function (): boolean {
      // on free card & on free plan (enable on free trial & pro licenses)
      if (this.isFree && this.$store.getters.licenseType === LicenseType.Free) {
        return true;
      }

      // FreeTrial account:
      // when upgrading from FreeTrial to Paid, only allow upgrade selected seats & storage >= current
      if (
        !this.isFree &&
        this.$store.getters.licenseType === LicenseType.FreeTrial
      ) {
        const associatesLength = this.$store.state.company?.associates?.length;
        const storageUsed = getStorageInNumber(
          this.$store.state.company?.account?.license_info?.total_storage_used
        );
        if (
          this.planCosts.upgrading.seats < associatesLength ||
          this.planCosts.upgrading.storage < storageUsed
        ) {
          return true;
        }
      }

      // on pro card and no changes to plan
      if (
        !this.isFree &&
        Number(this.planCosts.upgrading.total) ==
          Number(this.planCosts.current.total)
      ) {
        return true;
      }

      // no saved card
      if (!this.isFree && !this.cardOnFile) {
        return true;
      }

      return false;
    },
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit");
    },
  },
});
