
import { STORAGE_OPTIONS } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "AccountPlanProCard",
  props: {
    isCurrentLicense: Boolean
  },
  data() {
    return {
      baseProStorage: STORAGE_OPTIONS[0]
    };
  },
  computed: {},
  methods: {},
});
