
import { LicenseType } from "@/api";
import Vue from "vue";
import DowngradeFailedDialog from "./DowngradeFailedDialog.vue";

export default Vue.extend({
  components: { DowngradeFailedDialog },
  name: "AccountPlanSeats",
  props: {
    disabled: Boolean,
    total: String,
    currentSeatLimit: Number,
    seats: Number
  },
  data() {
    return {
      selectedSeats: 0,
      ticksLabels: {},
      downgradeFailedDialog: false,
      error: "Account has more seats than the new plan allows",
    };
  },
  computed: {
    isFreeTrialLicense: function (): boolean {
      return this.$store.getters.licenseType === LicenseType.FreeTrial;
    },
    currentSeatsList: function (): any {
      return [
        {
          key: this.currentSeatLimit,
          val: `${this.currentSeatLimit} seats (current plan)`,
        },
      ];
    },
    seatsList: function (): any[] {
      let temp = [] as any[];
      for (let i = 1; i <= 15; i++) {
        temp.push({
          key: i,
          val: `${i} seats`,
        });
      }
      return temp;
    },
    associatesLength: function (): number {
      return this.$store.state.company?.associates?.length;
    },
  },
  methods: {
    onChange() {
      if (this.selectedSeats < this.associatesLength) {
        this.selectedSeats = 0;
        this.downgradeFailedDialog = true;
      }
      this.$emit("onChange", this.selectedSeats);
    },
  },
  watch: {
    seats() {
      this.selectedSeats = this.seats
    }
  },
});
