
import {
  getStorageInNumber,
  mbToGB,
  STORAGE_OPTIONS,
  STORAGE_OPTION_LABELS,
} from "@/utils";
import Vue from "vue";
import DowngradeFailedDialog from "./DowngradeFailedDialog.vue";

export default Vue.extend({
  components: { DowngradeFailedDialog },
  name: "AccountPlanStorage",
  props: {
    total: String,
    storage: Number,
    currentStorageLimit: Number,
    disabled: Boolean,
  },
  data() {
    return {
      index: 0,
      downgradeFailedDialog: false,
      error: "Account has more storage than the new plan allows",
    };
  },
  computed: {
    ticksLabels: function (): string[] {
      const labels = [] as any;
      for (let i = 0; i < STORAGE_OPTIONS.length; i++) {
        if (STORAGE_OPTIONS[i] === this.currentStorageLimit) {
          labels.push(STORAGE_OPTION_LABELS[i]); // + " | current");
        } else {
          labels.push(STORAGE_OPTION_LABELS[i]);
        }
      }
      return labels;
    },
  },
  methods: {
    getStorageIndex(storage: number) {
      this.index = STORAGE_OPTIONS.findIndex((option) => option === storage);
      if (this.index < 0) {
        this.index = 0;
      }
    },
    onChange() {
      const newStorage = STORAGE_OPTIONS[this.index];
      const storageUsed = getStorageInNumber(
        this.$store.state.company?.account?.license_info?.total_storage_used
      );
      if (newStorage < storageUsed) {
        this.downgradeFailedDialog = true;
        this.getStorageIndex(this.storage);
        return;
      }
      this.$emit("onChange", newStorage);
    },
  },
  watch: {
    currentStorageLimit() {
      this.getStorageIndex(this.currentStorageLimit);
    },
    storage() {
      this.getStorageIndex(this.storage);
    },
  },
});
