
import { checkout, ICompany, IPaymentOrder } from "@/api";
import { Actions } from "@/store/models";
import { getDatetime } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "AccountPlanInvoiceHistoryDialog",
  components: {},
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      items: [] as IPaymentOrder[],
      headers: [
        { text: "DATE ISSUED", value: "created_datetime" },
        // { text: "INVOICE #", value: "id" },
        { text: "AMOUNT", value: "order_amount" },
        { text: "", value: "action" },
      ],
    };
  },
  computed: {
    company: function (): ICompany {
      return this.$store.state.company;
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    async getItems() {
      try {
        this.items = (await checkout.getPaymentOrders(this.company.id)).data;
        this.items.forEach((item) => {
          item.created_datetime = getDatetime(item.created_datetime);
          item.order_amount = ("$" +
            (item.order_amount / 100).toFixed(2)) as any;
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    download(id: number) {
      const url =
        window.location.origin + "/invoice/" + id + "?method=download";
      window.open(url, "_blank");
    },
  },
  watch: {},
});
