
import { mbToGB } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "AccountPlanPaymentSummary",
  props: {
    planCosts: Object,
    isFree: Boolean,
  },
  data() {
    return {
      today: ""
    };
  },
  computed: {
    currentStorageLimit: function (): string {
      return this.$store.state.company?.account?.license_info
        ?.total_storage_limit;
    },
    currentSeatLimit: function (): number {
      return this.$store.state.company?.account?.license_info
        ?.total_users_limit;
    },
    total: function (): string {
      return this.isFree ? "0" : this.planCosts.upgrading.total;
    },
    text: function (): string {
      return this.isFree
        ? "Please note all data associated with this account will be deleted upon downgrade."
        : "These prices do not include applicable taxes or any discounts.";
    },
  },
  created() {
    this.getPlanRenewDate();
  },
  methods: {
    getPlanRenewDate() {
      let d = new Date()
      d.setMonth(d.getMonth() + 1, 1)
      this.today = d.toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    }
  }
});
