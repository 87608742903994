
import { checkout, IBillingDetails } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";
import AccountPlanInvoiceHistoryDialog from "./AccountPlanInvoiceHistoryDialog.vue";
import { loadStripe } from '@stripe/stripe-js';

export default Vue.extend({
  components: { AccountPlanInvoiceHistoryDialog },
  name: "AccountPlanPayment",
  props: {
      paymentMethod: {},
  },
  data() {
    return {
      stripePromise: null as any,
      cardElement: null as any,
      isCardElementFocused: null as any,
      cardTokenObj: null as any,
      card: null as any,
      errorMessage: null as string | null,
      isLoading: false,
      billingDetails: {
        name: '',
        address: {
          address_zip : null as any,
        } as any,
      } as IBillingDetails,
      invoiceHistoryDialog: false,
      isEditing: false,
    };
  },
  computed: {
    cardOnFile(): string {
      return this.$store.state.company?.account?.card_info?.last4 || '';
    },
  },
  mounted() {
    this.checkCardElement();
  },
  methods: {
    checkCardElement() {
      if (this.$refs.cardElement) {
        this.initializeStripe();
      }
    },
    async initializeStripe() {
      this.stripePromise = await loadStripe(process.env.VUE_APP_STRIPE_PK);

      const stripe = await this.stripePromise;
      if (stripe) {
        const elements = stripe.elements();
        const cardStyle = {
            base: {
              color: '#FFFFFF',
              fontFamily: '"Roboto", sans-serif',
              fontSmoothing: 'antialiased',
              fontWeight : 400,
              fontSize: '15px',
              '::placeholder': {
                  color: '#aab7c4',
              },
            },
            invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
            },
        };
        this.cardElement = elements.create('card', {style: cardStyle, hidePostalCode: true});
        this.cardElement.mount('#card-element');

        this.cardElement.on('focus', () => {
          this.isCardElementFocused = true;
        });
        this.cardElement.on('blur', () => {
          this.isCardElementFocused = false;
        });

        this.cardElement.on('change', (event: any) => {
          if (event.error) {
            this.errorMessage = event.error.message;
          } else {
            this.errorMessage = null;
          }
        });
      }
    },
    
    handleElementChange(event: any) {
      if (event.error) {
        console.error('Error:', event.error.message);
      } else {
        console.log('Element changed:', event);
      }
    },

    async handleSubmit() {
      const stripe = await this.stripePromise;
      // const stripe = this.stripe;
      if (!stripe) return;

      this.isLoading = true;

      const { token, error } = await stripe.createToken(this.cardElement, {
        name: this.billingDetails.name,
        address_city: this.billingDetails.address.city,
        address_country: this.billingDetails.address.country,
        address_zip: this.billingDetails.address.postal_code,
        // address_zip: this.card.address_zip
      });

      if (error) {
        this.errorMessage = error.message ?? 'An unknown error occurred';
        this.isLoading = false;
      } else {
        this.card = {token : token.id};
        this.cardTokenObj = token;
        this.billingDetails.address.postal_code=token.card.address_zip;
        this.$emit('update:paymentMethod', {
          billing_details: this.billingDetails,
          card: {token : token.id}
        });
        this.$emit('save');
        this.cancelEdit();
        this.isLoading = false;
      }
    },
    onChange() {
      console.log('card element:', this.card);
      this.$emit('update:paymentMethod', {
        billing_details: this.billingDetails,
        card: this.card
      });
    },
    async edit() {
      this.isEditing = true;
      this.initializeStripe();
      try {
        const response = (await checkout.getPaymentMethods(this.$store.state.company?.id)).data.pop();
        if (response) {
          this.billingDetails = JSON.parse(response.billing_details);
          // const card = JSON.parse(response.card);
          // this.expDate =
            // (card.exp_month < 10 ? `0${card.exp_month}` : card.exp_month) +
            // "/" +
            // card.exp_year;
          // this.initializeStripe();
        }
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    cancelEdit() {
      this.isEditing = false;
    },
    save() {
      this.$emit("save");
      this.cancelEdit();
    },
  },
});
