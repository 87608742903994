
import Vue from "vue";

export default Vue.extend({
  name: "PaymentFailedDialog",
  components: {},
  props: {
    dialog: Boolean,
    order: Object,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    tryAgain() {
      this.close()
      this.$emit("checkout")
      
    },
  },
  watch: {},
});
