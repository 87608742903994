
import Vue from "vue";
import Logo from "../common/Logo.vue";
import {
  BASE_FREE_SUMMARY,
  BASE_PRO_SUMMARY,
  getCheckoutSummary,
} from "@/utils";

export default Vue.extend({
  name: "PaymentSummarylDialog",
  components: { Logo },
  props: {
    dialog: Boolean,
    isFree: Boolean,
    planCosts: Object,
  },
  data() {
    return {};
  },
  computed: {
    summary: function (): any[] {
      return getCheckoutSummary(this.planCosts)
    },
    total: function (): number {
      if (this.isFree) {
        return 0;
      }
      return this.planCosts.upgrading?.total;
    },
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    checkout() {
      this.$emit("checkout");
      this.close();
    },
  },
  watch: {},
});
