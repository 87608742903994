
import Vue from "vue";

export default Vue.extend({
  name: "AccountPlanFreeCard",
  props: {
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
});
