
import Vue from "vue";

export default Vue.extend({
  name: "BackButton",
  props: {
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
});
