
import Vue from "vue";
import AccountPlanStorage from "@/components/accountPlan/AccountPlanStorage.vue";
import AccountPlanSeats from "@/components/accountPlan/AccountPlanSeats.vue";
import {
  getDate,
  getStorageInNumber,
  IPlanCosts,
  mbToGB,
  STORAGE_OPTIONS,
} from "@/utils";
import AccountPlanPaymentSummary from "@/components/accountPlan/AccountPlanPaymentSummary.vue";
import AccountPlanDisclaimer from "@/components/accountPlan/AccountPlanDisclaimer.vue";
import {
  checkout,
  companies,
  IMakePaymentResponse,
  IPaymentMethodAddRequest,
  LicenseType,
} from "@/api";
import { Actions, Mutations } from "@/store/models";
import AccountPlanPayment from "@/components/accountPlan/AccountPlanPayment.vue";
import PaymentSuccessfulDialog from "@/components/accountPlan/PaymentSuccessfulDialog.vue";
import AccountPlanFreeCard from "@/components/accountPlan/AccountPlanFreeCard.vue";
import AccountPlanProCard from "@/components/accountPlan/AccountPlanProCard.vue";
import PaymentSummaryDialog from "@/components/accountPlan/PaymentSummaryDialog.vue";
import BackButton from "@/components/common/BackButton.vue";
import CompanyHeader from "@/components/common/gssiAdmin/CompanyHeader.vue";
import PaymentFailedDialog from "@/components/accountPlan/PaymentFailedDialog.vue";
import DowngradeFailedDialog from "@/components/accountPlan/DowngradeFailedDialog.vue";
import { getErrorMessage } from "@/store";
import UpgradeOptionsDialog from "@/components/accountPlan/UpgradeOptionsDialog.vue";

enum Plan {
  Free = "Free",
  Pro = "Pro",
}
export default Vue.extend({
  name: "AccountPlan",
  components: {
    AccountPlanStorage,
    AccountPlanSeats,
    AccountPlanPaymentSummary,
    AccountPlanDisclaimer,
    AccountPlanPayment,
    PaymentSuccessfulDialog,
    AccountPlanFreeCard,
    AccountPlanProCard,
    PaymentSummaryDialog,
    BackButton,
    CompanyHeader,
    PaymentFailedDialog,
    DowngradeFailedDialog,
    UpgradeOptionsDialog,
  },
  data() {
    return {
      LicenseType: LicenseType,
      plan: Plan.Pro,
      storage: STORAGE_OPTIONS[0],
      seats: 1,
      planCosts: {
        current: {},
        upgrading: {},
      } as IPlanCosts,
      paymentMethod: {
        card: {},
        billing_details: {
          address: {},
        },
      } as IPaymentMethodAddRequest,
      paymentSummaryDialog: false,
      paymentSuccessfulDialog: false,
      orderDetails: {} as any,
      paymentFailedDialog: false,
      downgradeFailedDialog: false,
      downgradeError: "",
      upgradeOptionsDialog: false,
    };
  },
  computed: {
    company: function (): any {
      return this.$store.state.company;
    },
    cardOnFile: function (): string {
      return this.$store.state.company?.account?.card_info?.last4;
    },
    isFree: function (): boolean {
      return this.plan === Plan.Free;
    },
    daysLeft: function (): number {
      return this.$store.getters.freeTrailDaysLeft;
    },
    currentPlan: function (): LicenseType {
      return this.$store.getters.licenseType;
    },
    isProLicense: function (): boolean {
      return this.$store.getters.licenseType === LicenseType.Pro;
    },
    isFreeTrialLicense: function (): boolean {
      return this.$store.getters.licenseType === LicenseType.FreeTrial;
    },
    currentStorageLimit: function (): number {
      return mbToGB(this.company?.account?.cao?.company?.total_storage_limit);
    },
    currentSeatLimit: function (): number {
      return this.$store.state.company?.account?.cao?.company
        ?.total_users_limit;
    },
  },
  mounted() {
    this.getInitialCheckoutCost();
    if (this.isFreeTrialLicense) {
      this.upgradeOptionsDialog = true;
    }
  },
  methods: {
    getInitialCheckoutCost() {
      if (
        !this.company?.account?.cao.company ||
        !this.$store.state.company?.associates?.length
      ) {
        setTimeout(() => {
          this.getInitialCheckoutCost();
        }, 1000);
        return;
      }
      // handle 50mb scenario
      if (!this.currentStorageLimit || this.currentStorageLimit < 1) {
        this.storage = STORAGE_OPTIONS[0];
      } else {
        this.storage = this.currentStorageLimit;
      }
      // Set seats to current seat limit
      this.seats = this.currentSeatLimit;

      this.updateCheckoutCost();
    },
    async updateCheckoutCost() {
      try {
        const response = (
          await checkout.getCheckoutCost({
            total_users: this.seats,
            storage: this.storage,
            company_id: this.company.id,
          })
        ).data.payment_summary;
        this.planCosts = {
          current: {
            licenseType: this.$store.getters.licenseType,
            tierCost: response.current_plan.tier_cost,
            storage: this.currentStorageLimit,
            storageCost: response.current_plan.storage_cost,
            seats: this.currentSeatLimit,
            seatsCost:
              response.current_plan.additional_users_cost ||
              (response.current_plan as any).additional_user_cost,
            total: response.current_plan.monthly_cost,
          },
          upgrading: {
            licenseType: this.isFree ? LicenseType.Free : LicenseType.Pro,
            tierCost: response.upgrading.tier_cost,
            storage: this.storage,
            storageCost: response.upgrading.storage_cost,
            seats: this.seats,
            seatsCost: response.upgrading.additional_users_cost,
            total: response.upgrading.monthly_cost,
          },
          additionalMonthlyTotal: response.additional_monthly_cost,
        };
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },

    async onStorageChange(val: number) {
      this.storage = val;
      this.updateCheckoutCost();
    },
    onSeatsChange(val: number) {
      this.seats = val;
      this.updateCheckoutCost();
    },

    isValidPaymentMethod() {
      const { card } = this.paymentMethod;
      if (!card || (!card.token && (!card.cvc || !card.exp_month || !card.exp_year || !card.number))) {
        return false;
      }
      if (
        !this.paymentMethod.billing_details?.name ||
        !this.paymentMethod.billing_details?.address?.country ||
        !this.paymentMethod.billing_details?.address?.city ||
        !this.paymentMethod.billing_details?.address?.postal_code
      ) {
        return false;
      }
      return true;
    },
    async updatePaymentMethod() {
      try {
        if (!this.isValidPaymentMethod()) {
          throw "Please enter payment information.";
        }
        const paymentMethod = await checkout.addPaymentMethod(
          this.paymentMethod,
          this.company.id
        );
        if (paymentMethod.data.error) {
          throw paymentMethod.data.error.message;
        }
        this.$store.dispatch(Actions.GetUserInfo);
        this.$store.dispatch(Actions.GetCompanyAccount);
        this.$store.commit(Mutations.SetToast, {
          text: "Billing updated successfully",
          isBottom: true,
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async checkout() {
      if (this.isFree) {
        this.proToFree();
      } else {
        this.paymentSummaryDialog = true;
      }
    },

    async makePayment() {
      try {
        if (!this.cardOnFile) {
          if (!this.isValidPaymentMethod()) {
            throw "Please enter payment information.";
          }
          const paymentMethod = await checkout.addPaymentMethod(
            this.paymentMethod,
            this.company.id
          );
          if (paymentMethod.data.error) {
            throw paymentMethod.data.error.message;
          }
          this.$store.dispatch(Actions.GetCompanyAccount);
        }
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
        return;
      }
      try {
        const response = await checkout.makePayment(
          {
            storage: this.storage,
            total_users: this.seats,
          },
          this.company.id
        );
        // suppress payment successful dialog if downgrading
        if (Number(this.planCosts.additionalMonthlyTotal) <= 0) {
          this.$store.commit(Mutations.SetToast, {
            text: "Plan updated successfully",
            isBottom: true,
          });
        } else {
          this.orderDetails = this.getOrderDetails(response);
          this.paymentSuccessfulDialog = true;
        }
      } catch (e) {
        this.orderDetails = {
          cardType: "card",
          cardNumber:
            this.cardOnFile ||
            this.paymentMethod?.card?.number?.replace(/.(?=.{4})/g, "x"),
        };
        this.paymentFailedDialog = true;
      } finally {
        this.$store.dispatch(Actions.GetUserInfo);
        await this.$store.dispatch(Actions.GetCompanyAccount);
        this.getInitialCheckoutCost();
      }
    },

    getOrderDetails(paymentResponse: IMakePaymentResponse) {
      return {
        items: [
          {
            key: "Total Amount Paid",
            val: paymentResponse.data.paymentIntent.metadata.invoice_amount,
          },
          {
            key: "Payment Method",
            val: JSON.parse(
              paymentResponse.data.paymentOrder.data.payment_method_info
            ).card?.brand?.toUpperCase(),
          },
          {
            key: "Transaction Date",
            val: getDate(
              paymentResponse.data.paymentOrder.data.created_datetime
            ),
          },
        ],
        transactionId: paymentResponse.data.paymentOrder.data.order_id,
        id: paymentResponse.data.paymentOrder.data.id
      };
    },
    async proToFree() {
      try {
        const resp = await companies.license.downgrade(
          this.$store.state.company.id
        );
        this.$store.dispatch(Actions.GetCompanyAccount);
        this.$router.back();
      } catch (e) {
        this.downgradeError = getErrorMessage(e);
        this.downgradeFailedDialog = true;
      }
    },
    isValidFreePlanDowngrade() {
      if (this.company?.associates?.length > 1) {
        return false;
      }
      const storageUsed = getStorageInNumber(
        this.$store.state.company?.account?.license_info?.total_storage_used
      );
      if (storageUsed > 0.05) {
        return false;
      }
      return true;
    },
  },
  watch: {
    isFree() {
      if (this.isFree) {
        if (!this.isValidFreePlanDowngrade()) {
          setTimeout(() => {
            this.plan = Plan.Pro as any;
          });
          this.downgradeError =
            "Account has more storage or seats than the new plan allows";
          this.downgradeFailedDialog = true;
          return;
        }
        this.seats = 1;
        this.storage = 1;
        this.updateCheckoutCost();
      } else {
        // switch back from free to pro, update selections
        if (this.seats === 1 && this.storage === 1) {
          this.getInitialCheckoutCost();
        }
        // pro -> free fails, back to pro - using previous selections
        else {
          this.updateCheckoutCost();
        }
      }
    },
  },
});
