
import Vue from "vue";

export default Vue.extend({
  name: "UpgradeOptionsDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    daysLeft: function (): number {
      return this.$store.getters.freeTrailDaysLeft;
    },
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    cancel() {
      this.close();
      this.$router.back();
    },
  },
  watch: {},
});
